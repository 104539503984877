import React, { useContext, useState } from 'react'
import { Header, Button, Input, Icon, Popup, Label } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect';
import './Login.css'
import { useHistory } from 'react-router';
import { store } from 'react-notifications-component';
import { AuthContext } from '../../context/AuthContext';
import { loginCall } from '../../apiCalls';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Login = () => {
    const history = useHistory()
    const [renderPassword, setRenderPassword] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [visiblePassword, setVisiblePassword] = useState(false)
    const { user, isFetching, error, dispatch } = useContext(AuthContext)
    const [forgotPassword, setForgotPassword] = useState(false)


    const handleClick = (e) => {
        e.preventDefault()
        if (email) {
            setRenderPassword(!renderPassword)
        } else {
            setEmailError(true)
            store.addNotification({
                message: "Please, enter Email address",
                type: "danger",
                container: "top-right",
                dismiss: {
                  duration: 3000,
                }
              });
        }
    }

    const handleFocus = () => {
        setEmailError(false)
    }

    const showPassword = () => {
        setVisiblePassword(!visiblePassword)
    }

    const handleChangeEmail = () => {
        setVisiblePassword(false)
        setPassword('')
        setRenderPassword(false)
    }

    const handleResetPasswordLink = (e) => {
        setForgotPassword(true)
        store.addNotification({
            message: "Please check your email to reset password",
            type: "info",
            container: "top-right",
            dismiss: {
              duration: 3000,
            }
          });
        axios.post(`https://donationkiosk.bettertrade.net/api/auth/reset/link?email=${email}`)
    }


    const handleLogin = (e) => {
        e.preventDefault();
        try {
            loginCall(
                { email, password },
                dispatch
            );
            history.push('/')
        } catch (error) {
            setEmail('');
            setPassword('');
        }
    }

    return (
        <div className={ isMobile ? "login_container_mobile" : "login_container"}>
                <Header as="h2">Please log in to your donor account</Header>
                <div>
                    {renderPassword === false ?
                        <form action="" className="login_form" key="email">
                            <Input
                                type="email"
                                fluid
                                className="login_input"
                                placeholder="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={emailError}
                                onFocus={() => handleFocus()}
                                required
                            />
                            <Button
                                className="login_continue_button"
                                fluid
                                color="yellow"
                                onClick={handleClick}
                            >
                                CONTINUE
                            </Button>
                            <div className="login_label">
                                No Account? Sign up for free
                            </div>
                            
                            <Link className="login_label" to="/admin/panel">
                                To sign in as a merchant, please click on this link
                            </Link>
                        </form>
                        :
                        <div>
                            <form className="login_form" onSubmit={handleLogin} key="password">
                                <div className="login_email_container">
                                    <h3>{email}</h3>
                                    <Popup
                                        content="Sign in as a different user"
                                        trigger={<Icon size="large" name="edit outline" link onClick={() => handleChangeEmail()} />}
                                        position="right center"
                                    />
                                </div>
                                <Input
                                    type={!visiblePassword ? 'password' : 'text'}
                                    placeholder="Password"
                                    required
                                    fluid
                                    className="login_input"
                                    onChange={(e) => setPassword(e.target.value)}
                                    icon={<Icon name={!visiblePassword ? 'eye' : 'eye slash'} link onClick={() => showPassword()} />}
                                />
                                <Button className="login_continue_button" fluid color="yellow" loading={isFetching} type="submit" >
                                    LOG IN
                                </Button>
                            </form>
                            {error && <div className="login_label_container">
                                <Label className="login_error_label">
                                    Wrong password or email
                                </Label>
                            </div>
                            }
                            <div className="login_label_container">
                                <Label className="login_reset_password_label" onClick={handleResetPasswordLink}>Forgot password</Label>
                            </div>
                        </div>
                    }
                </div>
        </div>
    )
}

export default Login
