export const getApiDonations = async (url) => {
    try {
        const res = await fetch(url);

        if(!res.ok) {
            console.error(res.status);
            return false;
        }

        return await res.json();
    } catch (error) {
        console.error(error);
        return false;
    }
}