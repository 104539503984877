// import { CircularProgress } from '@material-ui/core'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Input, Button, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { activationCall } from '../../apiCalls'
import { AuthContext } from '../../context/AuthContext'
import './Activation.css'

const Activation = () => {

    const { activationCode } = useParams()
    const [renderPassword, setRenderPassword] = useState(false)
    const [password, setPassword] = useState("")
    const { user, isFetching, error, dispatch } = useContext(AuthContext)


    const handleClick = (e) => {
        e.preventDefault()
        setRenderPassword(!renderPassword)
    }

    const handleActivation = (e) => {
        e.preventDefault();
        try {
            activationCall(
                { activationCode, password },
                dispatch
            );
            //   history.push('/api/account')
        } catch (error) {
            setPassword('');
        }
    }

    return (
        <div>
            <div className="activation">
                <Header as={'h2'}>Please Activate Your Donor Account</Header>
                <div>
                    <form onSubmit={handleActivation} key="password">
                        <Input
                            type="password"
                            placeholder="Password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button type="submit" color='yellow' loading={isFetching}>ACTIVATE</Button>
                        {error && <p className='activation_red_text'>Cannot Activate Account</p>}
                        <Link className='activation_link' to="/api/login">
                            <p className='activation_blue_text'>Already Have Account? Log in</p>
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Activation
