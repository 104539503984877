import React from 'react'
import { Icon } from 'semantic-ui-react'
import './style.css'

const CheckEmail = () => {
    return (
            <div className="check_email_container">
            <h2 className="check_email_title">Thank you for signing up for a Donation Kiosk Donor Account</h2>
            <Icon name="mail" color="grey" size="huge" link />
            <p><strong>Now check your email</strong></p>
            <p>...for an account activation email</p>
            </div>
    )
}

export default CheckEmail
