import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import "./Print.css";
import jsPDF from "jspdf";
import { RadioGroup, FormControlLabel, Radio, Grid, FormControl, Input, Select } from "@material-ui/core";
import html2canvas from "html2canvas";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { Form } from "semantic-ui-react";

const Print = () => {
  const { email } = useParams();
  const { id } = useParams();
  const history = useHistory();
  const [donation, setdonation] = useState({});
  const [selected, setSelected] = useState("On Behalf");
  const [proceed, setproceed] = useState(false);
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [emailToSend, setemailToSend] = useState(email);
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [error, setError] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [phone, setPhone] = useState("");
  const [createAccount, setCreateAccount] = useState(false);
  const { user } = useContext(AuthContext);
  const fullName = firstName + " " + lastName;
  useEffect(() => {
    const fetchDonation = async () => {
      const response = await axios.get(
        `https://donationkiosk.bettertrade.net/api/donations/${email}/${id}`
      );
      setdonation(response);
    };
    fetchDonation();
  }, []);

  useEffect(() => {
    checkIfEmailExists();
  }, [emailToSend]);

  const handleChange = (ev) => {
    setSelected(ev.target.value);
  };

  const checkIfEmailExists = async () => {
    const res = await axios.post(
      "https://donationkiosk.bettertrade.net/api/auth/exists",
      { email: emailToSend }
    );
    setEmailExists(res?.data);
  };

  const payload = {
    firstName: firstName,
    lastName: lastName,
    address: address,
    city: city,
    state: state,
    zip: zip,
    phone: phone,
    reason: donation?.data?.reason,
    email: emailToSend,
    amount: donation?.data?.amount,
    merchantName: donation?.data?.merchantName,
    merchantId: donation?.data?.merchantId,
  };
  const handleProceed = async (e) => {
    if (emailExists) {
      e.preventDefault();
      setError(true);
      return;
    }
    if (!firstName || !lastName || !address || !city || !email || !phone) {
      return;
    } else if (createAccount === true) {
      handleCreate()
      await axios.post("https://donationkiosk.bettertrade.net/api/pdf/send", payload);
      // history.push("/api/check");
    } else {
      setproceed(!proceed);
      setTimeout(function () {
        html2canvas(document.querySelector("#capture"), {
          scale: 2,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "pt");
          pdf.addImage(imgData, "PNG", 0, 0);
          var blob = pdf.output("blob");
          var formData = new FormData();
          formData.append("pdf", blob);
          formData.append("fileName", donation?.data?.id);
          formData.append("merchantId", donation?.data?.merchantId);
          axios.post("https://donationkiosk.bettertrade.net/api/pdf", formData, {
            "Content-Type": "multipart/form-data",
          });
        });
      }, 200);
    }
  };

  const handleCreate = () => {
    axios.post(
      "https://donationkiosk.bettertrade.net/api/auth/registration",
      payload
    );

    history.push("/api/check");
  };

  const printPDF = () => {
    html2canvas(document.querySelector("#capture2"), {
      scale: 1,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt");
      pdf.addImage(imgData, "PNG", 100, 0);
      pdf.autoPrint();
      pdf.output("dataurlnewwindow");
    });
  };

  function printPdfWithDetails() {
    html2canvas(document.querySelector("#capture"), {
      scale: 1,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt");
      pdf.addImage(imgData, "PNG", 100, 0);
      pdf.autoPrint();
      pdf.output("dataurlnewwindow");
    });
  }

  function downloadPdf() {
    html2canvas(document.querySelector("#capture2"), {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt");
      pdf.addImage(imgData, "PNG", 0, 0);

      pdf.save("receipt.pdf");
    });
  }

  const downloadPDFWithDetails = () => {
    html2canvas(document.querySelector("#capture"), {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt");
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("receipt.pdf");
    });
  };

  return (
    <Grid
      container
      justify="center"
      align="center"
      spacing={isMobile ? "0" : "5"}
      display={isMobile ? "column" : "row"}
      className={isMobile ? "print_container_mobile" : "print_container"}
    >
      {proceed === false ? (
        <>
          <Grid
            item
            className={
              isMobile ? "receipt_container_mobile" : "receipt_container"
            }
          >
            <Grid item id="capture2" backgroundColor="rgb(245, 245, 245)">
              <Grid item className="imageWrapper">
                <img
                  src="/stub.png"
                  alt=""
                  style={{
                    objectFit: "fill",
                    width: "50px",
                    height: "50px",
                    marginBottom: "10px",
                  }}
                />
                <h4>{donation?.data?.merchantName}</h4>
              </Grid>
              <Grid item className="time">
                <Typography variant="subtitle" color="inherit" align="center">
                  {"You have made a donation at " +
                    moment(donation?.data?.date).format("h:mm:ss A DD.MM.yyyy")}
                </Typography>
              </Grid>
              <Grid item className="amount">
                <p>$ &#160;</p>
                <strong style={{ fontSize: "40px" }}>
                  {(donation?.data?.amount / 100).toFixed(2)}
                </strong>
              </Grid>
              <Grid item className="reasons">
                <p>{donation?.data?.reason + ":"}</p>
                <p>{"$" + (donation?.data?.amount / 100).toFixed(2)}</p>
              </Grid>
              <hr />
              <Grid item className="total">
                <strong style={{ fontSize: "12px" }}>Total: </strong>
                <p style={{ fontSize: "12px" }}>
                  {"$" + (donation?.data?.amount / 100).toFixed(2)}
                </p>
              </Grid>
              <hr />
              <div style={{ padding: 50 }}></div>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl action="" className="registration">
              <Typography variant="subtitle2">Donor Profile </Typography>
              <RadioGroup
                row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
                onChange={handleChange}
                value={selected}
              >
                <FormControlLabel
                  value="Anonimous"
                  control={<Radio color="primary" />}
                  label="Anonimous"
                  style={{ color: "blue", padding: 0 }}
                />
                <FormControlLabel
                  style={{ color: "blue" }}
                  value="On Behalf"
                  control={<Radio color="primary" />}
                  label="On Behalf"
                />
              </RadioGroup>

              {selected === "On Behalf" ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="subtitle"
                      style={{ color: "gray", fontSize: "11px" }}
                    >
                      If you have an account, please login and your information
                      will be filled out automatically
                    </Typography>
                    <Link to="/" style={{ marginTop: "7px" }}>
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="subtitle"
                        color="primary"
                      >
                        Login
                      </Typography>
                    </Link>
                    {error && (
                      <Typography
                        style={{ color: "red", marginTop: "5px" }}
                        variant="subtitle"
                      >
                        This email already exists
                      </Typography>
                    )}
                  </div>
                  <div className="information_for_donation">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography variant="string">
                        Do you expect to obtain Tax Documents related related to
                        this donation?
                      </Typography>
                      <Typography variant="string">
                        Provide information for donation below:
                      </Typography>
                    </div>
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <input
                        
                        type="text"
                        placeholder="First Name"
                        style={{
                          marginRight: "10px",
                          height: "25px",
                          width: "50%",
                        }}
                        required
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <input
                      color="inherit"
                        type="text"
                        placeholder="Last Name"
                        required
                        outl
                        style={{ height: "25px", width: "50%"}}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Address"
                      style={{ width: "100%", height: "25px" }}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    />
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        placeholder="City"
                        required
                        style={{
                          width: "40%",
                          marginRight: "5px",
                          height: "25px",
                        }}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <input
                        type="option"
                        placeholder="State"
                        style={{
                          width: "30%",
                          marginRight: "5px",
                          height: "25px",
                        }}
                        onChange={(e) => setState(e.target.value)}
                      />
                      <input
                        type="text"
                        placeholder="Zip"
                        style={{ width: "30%", height: "25px" }}
                        required
                        onChange={(e) => setZip(e.target.value)}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        placeholder="Phone Number"
                        required
                        style={{
                          height: "25px",
                          width: "100%",
                          marginRight: "10px",
                        }}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <select
                        defaultValue="1"
                        fullWidth
                        style={{
                          height: "25px",
                          padding: 0,
                          textAlign: "center",
                        }}
                      >
                        <option value="1">Mobile</option>
                        <option value="2">Other</option>
                      </select>
                    </div>
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        placeholder="Email"
                        required
                        defaultValue={emailToSend}
                        onChange={(e) => setemailToSend(e.target.value)}
                        style={{
                          height: "25px",
                          width: "60%",
                          marginRight: "10px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "10px",
                      marginTop: "-15px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <input
                      style={{ marginTop: "15px" }}
                      type="checkbox"
                      onChange={(e) => setCreateAccount(!createAccount)}
                      size="small"
                      color="default"
                    />
                    <Typography align="center" variant="subtitle2">
                      I would like to create Donor Account
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <button
                      onClick={handleProceed}
                      style={{
                        backgroundColor: "rgb(253,200,32)",
                        padding: "10px",
                        marginBottom: "10px"
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <div className="printDownload">
                      <img
                        className="action"
                        onClick={downloadPdf}
                        src="/download.png"
                        alt=""
                      />
                      <img
                        className="action"
                        onClick={printPDF}
                        src="/print.png"
                        alt=""
                      />
                    </div>
                    <h3>*This page will expire within 24 hours.</h3>
                  </div>
                </div>
              )}
            </FormControl>
          </Grid>
        </>
      ) : (
        <Grid item
          className={isMobile ? "proceed_container_mobile" : "proceed_container"}
        >
            <div className="receipt_details" id="capture">
              <div className="imageWrapper">
                <h3
                  style={{
                    marginBottom: "10px",
                    color: "#ffffe6",
                    alignSelf: "flex-start",
                  }}
                >
                  Beneficiary:
                </h3>
                <img
                  src="/stub.png"
                  alt=""
                  style={{
                    objectFit: "fill",
                    width: "50px",
                    height: "50px",
                    marginBottom: "10px",
                  }}
                />
                <h4>{donation?.data?.merchantName}</h4>
              </div>
              <div className="donorInfo">
                <h3 style={{ marginBottom: "10px" }}>Donor:</h3>
                <p style={{ fontSize: "14px" }}>{firstName + " " + lastName}</p>
                <p style={{ fontSize: "14px" }}>{address}</p>
                <p style={{ fontSize: "14px" }}>
                  {city + " " + state + " " + zip}
                </p>
                <p style={{ fontSize: "14px" }}>{phone}</p>
                <p style={{ fontSize: "14px" }}>{emailToSend}</p>
              </div>
              <div className="amount">
                <p>$ &#160;</p>
                <strong style={{ fontSize: "40px" }}>
                  {(donation?.data?.amount / 100).toFixed(2)}
                </strong>
              </div>
              <div className="reasons">
                <p>{donation?.data?.reason + ":"}</p>
                <p>{"$" + (donation?.data?.amount / 100).toFixed(2)}</p>
              </div>
              <hr />
              <div className="total">
                <strong style={{ fontSize: "12px" }}>Total: </strong>
                <p style={{ fontSize: "12px" }}>
                  {"$" + (donation?.data?.amount / 100).toFixed(2)}
                </p>
              </div>
              <hr />
              <div
                style={{
                  padding: 50,
                  backgroundColor: "rgb(255, 255, 255)",
                }}
              ></div>
            </div>
          <Grid className="details">
            <Grid flexDirection="column" alignItems="center">
              <Grid
                style={{
                  border: "1px solid gray",
                  marginTop: "20px",
                  boxShadow: "4px 4px 8px 0px rgba(3, 20, 34, 0.2)",
                }}
              >
                <Typography fontWeight="bold" variant="h5">
                  Donor Profile
                </Typography>
                <Typography variant="h6">{fullName}</Typography>
                <Typography variant="h6">{emailToSend}</Typography>
                <Typography variant="h6">{phone}</Typography>
                <Typography variant="h6">{zip}</Typography>
                <Typography variant="h6">{address}</Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{
                      width: "200px",
                      height: "30px",
                      backgroundColor: "rgb(253,200,32)",
                      color: "black",
                      padding: 0,
                      marginBottom: "5px",
                    }}
                    onClick={() => handleCreate()}
                  >
                    Create Donor Account
                  </button>
                </div>
              </Grid>
              <hr />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  onClick={downloadPDFWithDetails}
                  src="/download.png"
                  alt=""
                />
                <img onClick={printPdfWithDetails} src="/print.png" alt="" />
              </div>
              <Typography
                align="left"
                variant="subtitle"
                style={{ marginBottom: "20px" }}
              >
                *This page will expire within 24 hours.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Print;
