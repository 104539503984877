import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { isMobile } from 'react-device-detect';
import { Popup, Icon } from 'semantic-ui-react'
import { AuthContext } from '../../context/AuthContext'
import './style.css'

const Header = () => {
    const { user } = useContext(AuthContext)
    const history = useHistory()

    const handleLogout =() => {
        localStorage.clear()
        history.push('/api/login')
        window.location.reload()
    }

    return (
        <div className={ isMobile ? "header_container_mobile" : "header_container"}>
            <div>
                <h3>Donation Kiosk</h3>
            </div>
            <div>
                {user?.firstName && user?.lastName ? (
                    <div className="header_content_container">
                        <div className={isMobile ? "header_welcome" : ""}>Welcome</div>
                        <div className="header_user_info">
                            {user?.firstName + " " + user?.lastName }
                        </div>
                        <div><Popup trigger={<Icon size="big" name="sign-out" onClick={() => handleLogout()} />} content="Logout" /></div>
                    </div>
                ): null}
            </div>
        </div>
    )
}

export default Header
