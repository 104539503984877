import React, { useState } from 'react';
import axios from 'axios'
import "./UploadedImage.css"
import { Button, Form, Modal, Dropdown, Table } from 'semantic-ui-react'

const device = [
    { key: 'Background', text: 'Background', value: '0' },
    { key: 'Header', text: 'Header', value: '1' },
    { key: 'Body', text: 'Body', value: '2' },
    { key: 'Footer', text: 'Footer', value: '3' }
]
const place = [
    { key: 'Clover Flex', text: 'Clover Flex', value: '1' },
    { key: 'Clover Mini', text: 'Clover Mini', value: '2' },
    { key: 'Clover Station Old', text: 'Clover Station Old', value: '3' },
    { key: 'Clover Station New', text: 'Clover Station New', value: '4' }
]

const UploadedImage = () => {
    const [open, setOpen] = React.useState(false);
    const [ImgFile, setImgFile] = useState();

    const handleSubmit = event => {
        event.preventDefault()
        const url = 'https://donationkiosk.bettertrade.net/api/settings/uploadImage?mid=NQWCTQAWCFC24&deviceType=2&placeType=1';
        const formData = new FormData();
        formData.append('image', ImgFile);
        const config = {
          headers: {
            "accept": "*/*",
            "Content-Type": "multipart/form-data"
          },
        };
        axios.post(url, ImgFile, config).then((response) => {
          console.log(response.data);
        });
    
    };

    return (
            <div className="uploaded_block">
                <div className="uploaded_header">
                    <h1>Images</h1>
                    
                    <Modal
                    className="uploaded_modal"
                    closeIcon
                    open={open}
                    trigger={<Button className="uploaded_button">Upload Image</Button>}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    >
                        <Modal.Header>Upload image</Modal.Header>
                        <Form className="uploaded_form" onSubmit={handleSubmit}>
                            <div className="uploaded_flex">
                                <Dropdown className='uploaded_dropdown' placeholder='Select device' fluid selection options={device}/>
                                <Dropdown className='uploaded_dropdown' placeholder='Select place' fluid selection options={place}/>
                            </div>
                            <input
                                type="file"
                                onChange={event => setImgFile(event.target.files[0])}
                            />
                            <Button type='submit' className="uploaded_submit">Submit</Button>
                        </Form>
                    </Modal>
                </div>
                <div className="uploaded_qr">
                    <Table fixed>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Device Type</Table.HeaderCell>
                            <Table.HeaderCell>Place Type</Table.HeaderCell>
                            <Table.HeaderCell>Width</Table.HeaderCell>
                            <Table.HeaderCell>Height</Table.HeaderCell>
                            <Table.HeaderCell>Link</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>

                        <Table.Body>
                        <Table.Row>
                            <Table.Cell>Clover Mini</Table.Cell>
                            <Table.Cell>Header</Table.Cell>
                            <Table.Cell>1280</Table.Cell>
                            <Table.Cell>216</Table.Cell>
                            <Table.Cell>Open</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Clover Mini</Table.Cell>
                            <Table.Cell>Header</Table.Cell>
                            <Table.Cell>1280</Table.Cell>
                            <Table.Cell>216</Table.Cell>
                            <Table.Cell>Open</Table.Cell>
                        </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
    )
}

export default UploadedImage
