import {React, useEffect, useState} from 'react'
import "./Customers.css"
import { getApiDonations } from '../../utils/network'
import { MERCHANT_API_DONATIONS_GET } from '../../utils/type'

const Customers = () => {
    const [customers, setCustomers] = useState(null);

    const getCustomers = async (url) => {
        const res = await getApiDonations(url);
        const customersList = res.map(({ email, firstName, lastName}) => {
            return {
                email,
                firstName, 
                lastName
            }
        })

        uniq_fast(customersList)
        function uniq_fast(customersList) {
            var seen = {};
            var out = [];
            var len = customersList.length;
            var j = 0;
            for(var i = 0; i < len; i++) {
                 var item = customersList[i].email;
                 if(seen[item] !== 1) {
                       seen[item] = 1;
                       out[j++] = customersList[i];
                 }
            }
            console.log(out)
            setCustomers(out)
        }
    }

    useEffect(() => {
        getCustomers(MERCHANT_API_DONATIONS_GET);
    }, []);

    const [searchTrem, setSearchTrem] = useState('');
    return (
            <div className="customers_block">
                <div className="customers_header">
                    <h1>Customers</h1>
                    <input placeholder='Search' 
                    onChange={(event) => {
                        setSearchTrem(event.target.value)
                    }} />
                </div>
                {customers && (
                <div className='customers_card_block'>
                        {customers.filter((val) => {
                            if (searchTrem == "") {
                                return val
                            }else if (val.email.toLowerCase().includes(searchTrem.toLowerCase())) {
                                return val
                            }
                        }).map((val, key) => {
                            return (
                                <div className="customers_card" key={key}>
                                    <div className='customers_block_img'>
                                        <img className='customers_img' src={val.image}></img>
                                    </div>
                                    <div className='customers_text_block'>
                                        <p className='customers_title'>{val.firstName} {val.lastName}</p>
                                        <p className='customers_mail'>{val.email}</p>
                                    </div>
                                </div>
                            )
                        })}
                </div>
                )}
            </div>
    )
}

export default Customers
