import React from 'react'
import { Link } from 'react-router-dom'
import './sidesMenu.css'

const sidesMenu = () => {
    return (
            <div className="sides_menu">
                <h2>Better Trade Test</h2>
                <Link to="/admin/panel" className='active'>Donations</Link>
                <Link to="/admin/panel/customers">Customers</Link>
                <Link to="/admin/panel/reports">Reports</Link>
                <Link to="/admin/panel/uploaded-image">Upload Image</Link>
            </div>
    )
}

export default sidesMenu
