import React, {useEffect} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {CircularProgress} from "@mui/material";
import {useHistory} from "react-router-dom";

function restCall(method, uri, body, authHeader) {
    // const backend = 'http://localhost:8080'
    const backend = ''
    return fetch(`${backend}${uri}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader
        },
        body: JSON.stringify(body)
    })
}

const Loggedpage = ({setLoginInfo}) => {

    const history = useHistory();

    function navigate(uri) {
        history.push(uri);
    }

    useEffect(() => {
        console.log('Loggedpage mounted')
        const searchParams = new URLSearchParams(window.location.search);
        const merchantId = searchParams.get("merchant_id")
        const employeeId = searchParams.get("employee_id")
        const clientId = searchParams.get("client_id")
        const code = searchParams.get("code")

        console.log(`clientId = ${clientId}`)

        restCall("POST",
            '/donationkiosk/public/api/auth/withaccesstoken',
            {
                merchantId: merchantId,
                employeeId: employeeId,
                clientId: clientId,
                code: code
            })
            .then((response) => {
                console.log('onAppTokenReceived')
                console.log(response)
                if (response.status === 200) {
                    navigate("/merchant");
                    let authHeader = response.headers.get('Authorization');
                    console.log(authHeader)
                    setLoginInfo({
                        isLogged: true,
                        token: authHeader
                    })
                } else {
                    navigate("/merchant/loginfailed");
                    setLoginInfo({
                        isLogged: false,
                        token: null
                    })
                }
            })
            .catch(onAuthFail)
    });


    function onAuthFail(err) {
        console.log('onAuthFail')
        console.log(err)
        navigate("/merchant/loginfailed");
        setLoginInfo({
            isLogged: false,
            region: null,
            token: null
        })
    }

    return (
        <>
            <Box sx={{width: '100%', paddingTop: '7px', marginLeft: '24px'}}>
                <Box>
                    <Typography variant="h6" sx={{fontSize: 27, fontWeight: 500}}>Logged via Clover</Typography>
                </Box>
                <CircularProgress/>
            </Box>

        </>
    )
}

export default Loggedpage