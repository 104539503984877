import React, { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect';
import { List, Header, Input, Icon, Label, Checkbox, Button, Dropdown, Popup } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import { AuthContext } from '../../context/AuthContext';
import UserCard from '../../components/card/UserCard';
import axios from 'axios';
import jsPDF from 'jspdf'
import moment from 'moment';
import html2canvas from 'html2canvas';
import './Account.css'

const Account = () => {
    const { user } = useContext(AuthContext);
    const [donations, setDonations] = useState([])
    const [userInfo, setUserInfo] = useState({})
    const [search, setSearch] = useState("")
    const [openFilter, setOpenFilter] = useState(false)
    const [name, setName] = useState('')
    const [date, setDate] = useState('')
    const [dropdownValue, setDropdownValue] = useState('')
    const [filteredDonation, setFilteredDonation] = useState([])

    useEffect(() => {
        const fetchDonations = async () => {
            const response = await axios.get(`https://donationkiosk.bettertrade.net/api/donations/user/${user.email}`)
            setDonations(response.data)
        }
        fetchDonations()
    }, [user.email])

    useEffect(() => {
        const fetchUserInfo = async () => {
            const response = await axios.get(`https://donationkiosk.bettertrade.net/api/auth/info/${user.email}`)
            setUserInfo(response)
        }
        fetchUserInfo()
    }, [user.email])

    useEffect(() => {
        setFilteredDonation(donations)
    }, [donations])

    useEffect(() => {
        if (search === '') {
            setFilteredDonation(donations)
        } else {
            const newArray = donations.filter(item => {
                if (item.merchantName.toLowerCase().includes(
                    search.toLowerCase()) ||
                    item.reason.toLowerCase().includes(
                        search.toLowerCase()
                    )) {
                    return item
                }
            })
            setFilteredDonation(newArray)
        }
    }, [search])

    useEffect(() => {
        if (name === '' && !date) {
            setFilteredDonation(donations)
        } else {
            const newArray = filteredDonation.filter((item) => item.reason.toLowerCase().includes(name.toLowerCase()))
            setFilteredDonation(newArray)
        }
    }, [name])

    useEffect(() => {
        if (name === '' && !date) {
            setFilteredDonation(donations)
        } else {
            const newArray = filteredDonation.filter((item) => moment(new Date(item.date)).format('DD.MM.YYYY') === moment(new Date(date)).format('DD.MM.YYYY'))
            setFilteredDonation(newArray)
        }
    }, [date])


    const downloadPDF = (id) => {
        html2canvas(document.querySelector(`#capture${id}`), {
            scale: 2,
            onclone: function (doc) {
                doc.querySelector(`#capture${id}`).style.display = ''

            }
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'pt');
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save("receipt.pdf");
        })
    }

    const handleOpenFilter = () => {
        if (openFilter) {
            setOpenFilter(false)
            setDate('')
            setName('')
        } else {
            setOpenFilter(true)
            setSearch('')
        }
    }

    const handleChange = (e) => {
        setName(e.target.value)
    }

    const handleChangeDate = (date) => {
        setDate(date)
    }

    const options = [
        { key: 'download', icon: 'download', text: 'DOWNLOAD', value: 'download' },
        { key: 'view', icon: 'eye', text: 'VIEW', value: 'view' },
    ]

    const handleChangeDropdown = (e, data) => {
        switch (data.value) {
            case 'download':
                downloadPDF(data.id)
                setDropdownValue('0')
                break;
            case 'view':
                window.open(`/view/${data.id}`, "_blank")
                setDropdownValue('0')
                break;
            default:
                setDropdownValue('0')
                break;
        }
    }

    const handleClickPopup = (name, id) => {
        switch (name) {
            case 'download':
                downloadPDF(id)
                break;
            case 'view':
                window.open(`/view/${id}`, "_blank")
                break;
            default:
                break;
        }
    }


    return (
        <div className={isMobile ? "donation_container_mobile" : "donation_container"}>
            <div className={isMobile ? "account_user_card_container_mobile" : "account_user_card_container"}>
                <UserCard user={user} />
            </div>
            <div>
                <div className={isMobile ? "account_header_mobile" : "account_header"}>
                    <Header as="h2">Donations</Header>
                    <div className='account_search_container'>
                        <Input
                            icon='search'
                            iconPosition='left'
                            value={search}
                            placeholder="Search Inbox and Folders"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Label className='account_filter_label'>
                            <Icon name='options' size="big" color='blue' link onClick={() => handleOpenFilter()} onDoubleClick={(e) => { e.preventDefault() }} />
                            FILTERS
                        </Label>
                    </div>
                </div>
                {openFilter ?
                    <div className='account_filter_container'>
                        <div>
                            <Input
                                value={name}
                                onChange={(e) => handleChange(e)}
                                placeholder="Donated To: Organization / Cause"
                            />
                        </div>
                        <ReactDatePicker dateFormat="dd.MM.yyyy" selected={date ? new Date(date) : null} placeholderText="Date" onChange={(date) => handleChangeDate(date)} />
                    </div> : null}
                {isMobile ? (
                    <div className="account_list_container">
                        <List>
                            {filteredDonation.map((item) => (
                                <List.Item key={item?.id}>
                                    <div className="account_list_item_mobile">
                                        <div className='account_list_item_status'></div>
                                        <div>
                                            <div>
                                                <strong>{"Reason: " + item.reason}</strong>
                                                <p className='account_list_text'>To: {" " + item.merchantName}</p>
                                            </div>
                                            <div>{"$" + (item.amount / 100).toFixed(2)}</div>
                                            <div>
                                                <strong>{moment(item.date).format("DD/MM/YYYY")}</strong>
                                                <p className='account_list_text'>{moment(item.date).format("h:mm:ss A")}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <Popup
                                                size='small'
                                                pinned
                                                on='click'
                                                trigger={<Icon name='download' color='blue' size='big' />}
                                                position='left center'
                                                content={<div className='account_popup_container_mobile'>
                                                    <Icon name="download" size='big' link onClick={() => handleClickPopup('download', item.id)} />
                                                    <Icon name="eye" size='big' link onClick={() => handleClickPopup('view', item.id)} />
                                                </div>}
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        backgroundColor: 'rgb(255, 255, 255)',
                                        width: '21vw',
                                        height: 'unset',
                                        display: 'none'
                                    }} id={"capture" + item.id} >
                                        <div className="imageWrapper">
                                            <h3 style={{ marginBottom: '10px', color: '#ffffe6', alignSelf: 'flex-start' }}>Beneficiary:</h3>

                                            <img src="/stub.png" alt="" style={{
                                                objectFit: 'fill',
                                                width: '50px', height: '50px', marginBottom: ''
                                            }} />
                                            <p style={{ fontSize: '14px' }}>{item?.merchantName}</p>
                                        </div>
                                        <div className="donorInfo">
                                            <h3 style={{ marginBottom: '10px', color: '#ffffe6' }}>Donor:</h3>
                                            <p style={{ fontSize: '14px' }}>
                                                {userInfo?.data?.firstName + ' ' + userInfo?.data?.lastName}</p>
                                            <p style={{ fontSize: '14px' }}>
                                                {userInfo?.data?.city + " " + userInfo?.data?.state + " " + userInfo?.data?.zip}</p>
                                            <p style={{ fontSize: '14px' }}>{userInfo?.data?.phone}</p>
                                            <p style={{ fontSize: '14px' }}>{user?.email}</p>
                                        </div>
                                        <div className="amount">
                                            <p>$ &#160;</p><strong style={{ fontSize: '40px' }}>{(item?.amount / 100).toFixed(2)}</strong>
                                        </div>
                                        <div className="reasons">
                                            <p>{item?.reason + ":"}</p>
                                            <p>{"$" + (item?.amount / 100).toFixed(2)}</p>
                                        </div>
                                        <hr />
                                        <div className="total">
                                            <strong style={{ fontSize: '12px' }}>Total: </strong>
                                            <p style={{ fontSize: '12px' }}>{"$" + (item?.amount / 100).toFixed(2)}</p>
                                        </div>
                                        <hr />
                                    </div>
                                </List.Item>
                            ))}
                        </List>

                    </div>
                ) : (
                    <div className="account_list_container">
                        <div className="account_list_header">
                            <div />
                            <Label className='account_list_label'>Donated To: Organization / Cause</Label>
                            <Label className='account_list_label'>Amount Donated</Label>
                            <Label className='account_list_label'>Date / Time</Label>
                            <div />
                        </div>
                        <List>
                            {filteredDonation.map((item) => (
                                <List.Item key={item?.id}>
                                    <div className="account_list_item">
                                        <div className='account_list_item_status'></div>
                                        <div>
                                            <strong>{"Reason: " + item.reason}</strong>
                                            <p className='account_list_text'>To: {" " + item.merchantName}</p>
                                        </div>
                                        <div>{"$" + (item.amount / 100).toFixed(2)}</div>
                                        <div>
                                            <strong>{moment(item.date).format("DD/MM/YYYY")}</strong>
                                            <p className='account_list_text'>{moment(item.date).format("h:mm:ss A")}</p>
                                        </div>
                                        <div>
                                            <Button.Group color='grey' size='mini' fluid>
                                                <Button size='mini' content="DOWNLOAD" fluid />
                                                <Dropdown
                                                    className='button icon'
                                                    floating
                                                    id={item.id}
                                                    value={dropdownValue}
                                                    onChange={(e, data) => handleChangeDropdown(e, data)}
                                                    options={options}
                                                    trigger={<></>}
                                                />
                                            </Button.Group>
                                        </div>
                                    </div>
                                    <div style={{
                                        backgroundColor: 'rgb(255, 255, 255)',
                                        width: '21vw',
                                        height: 'unset',
                                        display: 'none'
                                    }} id={"capture" + item.id} >
                                        <div className="imageWrapper">
                                            <h3 style={{ marginBottom: '10px', color: '#ffffe6', alignSelf: 'flex-start' }}>Beneficiary:</h3>

                                            <img src="/stub.png" alt="" style={{
                                                objectFit: 'fill',
                                                width: '50px', height: '50px', marginBottom: ''
                                            }} />
                                            <p style={{ fontSize: '14px' }}>{item?.merchantName}</p>
                                        </div>
                                        <div className="donorInfo">
                                            <h3 style={{ marginBottom: '10px', color: '#ffffe6' }}>Donor:</h3>
                                            <p style={{ fontSize: '14px' }}>
                                                {userInfo?.data?.firstName + ' ' + userInfo?.data?.lastName}</p>
                                            <p style={{ fontSize: '14px' }}>
                                                {userInfo?.data?.city + " " + userInfo?.data?.state + " " + userInfo?.data?.zip}</p>
                                            <p style={{ fontSize: '14px' }}>{userInfo?.data?.phone}</p>
                                            <p style={{ fontSize: '14px' }}>{user?.email}</p>
                                        </div>
                                        <div className="amount">
                                            <p>$ &#160;</p><strong style={{ fontSize: '40px' }}>{(item?.amount / 100).toFixed(2)}</strong>
                                        </div>
                                        <div className="reasons">
                                            <p>{item?.reason + ":"}</p>
                                            <p>{"$" + (item?.amount / 100).toFixed(2)}</p>
                                        </div>
                                        <hr />
                                        <div className="total">
                                            <strong style={{ fontSize: '12px' }}>Total: </strong>
                                            <p style={{ fontSize: '12px' }}>{"$" + (item?.amount / 100).toFixed(2)}</p>
                                        </div>
                                        <hr />
                                    </div>
                                </List.Item>
                            ))}
                        </List>

                    </div>
                )}
            </div>
        </div>
    )
}

export default Account
