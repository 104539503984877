import {React, useEffect, useState} from 'react'
import "./Donations.css"
import { Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { getApiDonations } from '../../utils/network'
import { MERCHANT_API_DONATIONS_GET } from '../../utils/type'

const Donations = () => {
    const [donations, setDonations] = useState(null);

    const getDonations = async (url) => {
        const res = await getApiDonations(url);
        const donationsList = res.map((element) => {
            return {
                email: element.email
            }
        })

        setDonations(donationsList)
    }

    useEffect(() => {
        getDonations(MERCHANT_API_DONATIONS_GET);
    }, []);
    
    return (
            <div className="donations_block">
                <h1>Donations</h1>
                <div className="donations_card">
                    <p className='donations_title'>Send Donation Request With Link</p>
                    <Dropdown text='Actions' className='donations_dropdown'>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to='/admin/panel/create'>Create</Dropdown.Item>
                            <Dropdown.Item>View</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="donations_card">
                    <p className='donations_title'>Create Donation QR Code</p>
                    <Dropdown text='Actions' className='donations_dropdown'>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to='/admin/panel/create'>Create</Dropdown.Item>
                            <Dropdown.Item>View</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
    )
}

export default Donations