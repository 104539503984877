import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import SidesMenu from '../../components/sidesMenu/sidesMenu';
import Donations from '../Donations/Donations';
import Customers from '../Customers/Customers';
import "./KioskAdmin.css"
import Reports from '../Reports/Reports';
import Create from '../Create/Create';
import UploadedImage from '../UploadedImage/UploadedImage';

const KioskAdmin = () => {
    return (
        <Router>
            <div className="admin_body">
                <SidesMenu />
                
                <div className='admin_content'>
                    <Switch>
                        <Route exact path="/admin/panel">
                            <Donations />
                        </Route>
                        <Route path="/admin/panel/customers">
                            <Customers />
                        </Route>
                        <Route path="/admin/panel/reports">
                            <Reports />
                        </Route>
                        <Route path="/admin/panel/create">
                            <Create />
                        </Route>
                        <Route path="/admin/panel/uploaded-image">
                            <UploadedImage />
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    )
}

export default KioskAdmin
