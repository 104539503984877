import React from 'react'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import './style.css'

const Footer = () => {
    return (
        <div className={ isMobile ? "footer_container_mobile" : "footer_container"}>
            <div className={isMobile ? "footer_name_container_mobile" : "footer_name_container"}>
                <div className={isMobile ? "footer_small_text_mobile" : "footer_small_text"}>Powered by</div>
                <strong className="footer_huge_text">DONATION KIOSK</strong>
            </div>
            <div className={isMobile ? "footer_info_mobile" : "footer_info"}>
                <div className={isMobile ? "footer_small_text_mobile" : "footer_small_text"}>
                    <div className="footer_link_container">
                        <a target="_blank" href="https://bettertrade.net/pages/eula/" className="footer_link_item" rel="noreferrer" >Terms of Use</a>
                        <div>|</div>
                        <a target="_blank" href="https://bettertrade.net/pages/privacy-policy/" className="footer_link_item" rel="noreferrer" >Privacy Policy </a>
                    </div>
                </div>
                <div className="footer_normal_text">Copyright {moment(new Date()).format('YYYY')} Better Trade, Inc. All rights reserved</div>
            </div>
        </div>
    )
}

export default Footer
