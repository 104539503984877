import React from 'react'
import "./Create.css"
import { Icon } from 'semantic-ui-react'
import QRCode from 'react-qr-code'

const Create = () => {
    
    return (
            <div className="create_block">
                <h1>Donations / Create Donation QR Code</h1>
                <div className="create_qr">
                    <div className='create_qr_content'>
                        <div className='create_qr_content_title'>
                            <div className='create_qr_content_title_color'></div>
                            <input className='create_qr_content_title_text' type="text" placeholder='title'></input>
                        </div>
                        <div className='create_qr_content_flex'>
                            <div className='create_qr_content_reason'>
                                <div className='create_qr_content_reason_text'>Donation Reason</div>
                                <input className='create_qr_content_reason_input' type="text"></input>
                            </div>
                            <div className='create_qr_content_ammount'>
                                <div className='create_qr_content_ammount_text'>Donation Amount</div>
                                <input className='create_qr_content_ammount_input' type="text"></input>
                            </div>
                        </div>
                        <div className='create_qr_content_textarea'>
                            <div className='create_qr_content_textarea_text'>Additional Information About Donation</div>
                            <textarea className='create_qr_content_textarea_input' rows={4}></textarea>
                        </div>
                        <div className='create_qr_content_card mt-2'>
                            <div className='create_qr_content_card_icon'>
                                <Icon disabled name='pencil alternate' />
                            </div>
                            <div className='create_qr_content_card_text'>Set Colors</div>
                        </div>
                        <div className='create_qr_content_card'>
                            <div className='create_qr_content_card_icon'>
                                <Icon disabled name='image outline' />
                            </div>
                            <div className='create_qr_content_card_text'>Add Logo Image</div>
                        </div>
                        <div className='create_qr_content_card'>
                            <div className='create_qr_content_card_icon'>
                                <Icon disabled name='columns' />
                            </div>
                            <div className='create_qr_content_card_text'>Customize Design</div>
                        </div>
                    </div>
                    <div className='create_qr_result'>
                        <div className='create_qr_result_img'>
                            <QRCode
                                //https://www.npmjs.com/package/react-qr-code
                                className=''
                                value=''
                                size="128"
                            />
                        </div>
                        <div className='create_qr_result_button_block'>
                            <button className='create_qr_result_button_create'>Create QR Code</button>
                            <button className='create_qr_result_button_download'>Download PNG</button>
                        </div>
                        <div className='create_qr_result_button_download_block'>
                            <button className='create_qr_result_button_type'>SVG</button>
                            <button className='create_qr_result_button_type'>PDF</button>
                            <button className='create_qr_result_button_type'>EPS</button>
                        </div>
                        <p className='create_qr_result_text'>
                            *no support for color gradients
                        </p>
                    </div>
                </div>
            </div>
    )
}

export default Create