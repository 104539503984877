import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { Input, Button, Icon } from 'semantic-ui-react'
import { store } from 'react-notifications-component';
import { useParams } from 'react-router';
import { resetCall } from '../../apiCalls';

const ResetPassword = () => {
    const { activationCode } = useParams()
    const { user, isFetching, error, dispatch } = useContext(AuthContext)
    const [password, setPassword] = useState("")
    const [confirm, setConfirm] = useState("")
    const [visiblePassword, setVisiblePassword] = useState(false)
    const [visibleConfirm, setVisibleConfirm] = useState(false)

    const showPassword = () => {
        setVisiblePassword(!visiblePassword)
    }

    const showConfirm = () => {
        setVisibleConfirm(!visibleConfirm)
    }

    const handleReset = (e) => {
        e.preventDefault()
        if (password !== confirm) {
            store.addNotification({
                message: "Passwords don't match",
                type: "danger",
                container: "top-right",
                dismiss: {
                    duration: 3000,
                }
            });
        } else {
            try {
                resetCall(
                    { activationCode, password },
                    dispatch
                );
            } catch (error) {
                setPassword('');
            }
        }
    }
    return (
        <div className="activation">
            <form onSubmit={handleReset} key="password">
                <Input
                    type={!visiblePassword ? 'password' : 'text'}
                    placeholder="New Password"
                    required
                    icon={<Icon name={!visiblePassword ? 'eye' : 'eye slash'} link onClick={() => showPassword()} />}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Input
                    type={!visibleConfirm ? 'password' : 'text'}
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirm(e.target.value)}
                    required
                    icon={<Icon name={!visibleConfirm ? 'eye' : 'eye slash'} link onClick={() => showConfirm()} />}
                />
                <Button type="submit" color='yellow' size='small' loading={isFetching}>LOG IN</Button>
            </form>
        </div>
    )
}

export default ResetPassword
