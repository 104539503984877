import axios from "axios";

export const loginCall = async (userCredentials, dispatch) =>{
dispatch({type: "LOGIN_START"});
    try {
        const res = await axios.post("https://donationkiosk.bettertrade.net/api/auth/login", userCredentials);
        dispatch({type : "LOGIN_SUCCESS", payload: res.data});

    } catch (error) {
        dispatch({type : "LOGIN_FAILURE", payload: error});
    }
}

export const activationCall = async (userCredentials, dispatch) =>{
    dispatch({type: "LOGIN_START"});
        try {
            const res = await axios.post("https://donationkiosk.bettertrade.net/api/auth/activation", userCredentials);
            dispatch({type : "LOGIN_SUCCESS", payload: res.data});
    
        } catch (error) {
            dispatch({type : "LOGIN_FAILURE", payload: error});
        }
    }
    export const resetCall = async (userCredentials, dispatch) =>{
        dispatch({type: "LOGIN_START"});
            try {
                const res = await axios.post("https://donationkiosk.bettertrade.net/api/auth/reset", userCredentials);
                dispatch({type : "LOGIN_SUCCESS", payload: res.data});
            } catch (error) {
                dispatch({type : "LOGIN_FAILURE", payload: error});
            }
        }
