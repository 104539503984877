import {React, useEffect, useState} from 'react'
import "./Reports.css"
import { getApiDonations } from '../../utils/network'
import { MERCHANT_API_DONATIONS_GET, USER_API_DONATIONS_GET } from '../../utils/type'
import { Dropdown } from 'semantic-ui-react'

const Reports = () => {
    const [donations, setDonations] = useState(null);
    const [userDonations, setUserDonations] = useState(null);

    const getDonations = async (url) => {
        const res = await getApiDonations(url);
        const donationsList = res.map((element) => {
            return {
                email: element.email,
                amount: element.amount,
                reason: element.reason,
                date: element.date
            }
        })

        setDonations(donationsList)
        console.log(donationsList)
        uniq_fast(donationsList)
        function uniq_fast(donationsList) {
            var seen = {};
            var out = [];
            var len = donationsList.length;
            var j = 0;
            for(var i = 0; i < len; i++) {
                 var item = donationsList[i].email;
                 if(seen[item] !== 1) {
                       seen[item] = 1;
                       out[j++] = donationsList[i];
                 }
            }
            console.log(out)
        }
    }

    const getUserDonations = async (url) => {
        const res = await getApiDonations(url);
        const userDonationsList = res.map((element) => {
            return {
                amount: element.amount
            }
        })
        console.log(userDonationsList)
        setUserDonations(userDonationsList)
    }

    useEffect(() => {
        getDonations(MERCHANT_API_DONATIONS_GET);
    }, []);

    useEffect(() => {
        getUserDonations(USER_API_DONATIONS_GET + "info@bettertrade.net");
    }, []);

    return (
            <div className="reports_block">
                <div className="reports_header">
                    <h1>Reports</h1>
                    
                    <Dropdown text='Actions' className='donations_dropdown'>
                        <Dropdown.Menu>
                            <Dropdown.Item value="View">View</Dropdown.Item>
                            <Dropdown.Item value="View1">View1</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className='reports_card_block'>
                {userDonations && (
                    
                    <div className='reports_card'>
                        {userDonations.map((val, key) =>{
                                <div className="" key={key}>
                                    {val.amount}
                                </div>
                        })}
                        <h2 className='reports_card_header'>Donations</h2>
                        <div className='reports_row'>
                            <div className='reports_row_text'>Text</div>
                            <div className='reports_row_value'>5.00</div>
                        </div>
                        <div className='reports_row'>
                            <div className='reports_row_text'>Text</div>
                            <div className='reports_row_value'>5.00</div>
                        </div>
                        <div className='reports_row'>
                            <div className='reports_row_text'>Text</div>
                            <div className='reports_row_value'>5.00</div>
                        </div>
                        <div className='reports_row'>
                            <div className='reports_row_text'>Text</div>
                            <div className='reports_row_value'>5.00</div>
                        </div>
                        <div className='reports_button_block'>
                            <button className='reports_button'>Details</button>
                        </div>
                    </div>
                )}
                </div>
            </div>
    )
}

export default Reports
