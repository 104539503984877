import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const MerchantLoginFailed = () => {

    return (
        <>
            <Box sx={{width: '100%', paddingTop: '7px', marginLeft: '24px'}}>
                <Box>
                    <Typography variant="h6" sx={{fontSize: 27, fontWeight: 500}}>Merchant Login Failed</Typography>
                </Box>
            </Box>

        </>
    )
}

export default MerchantLoginFailed