import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css'
import 'react-notifications-component/dist/theme.css'
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';

ReactDOM.render(
  // <React.StrictMode>
    <AuthContextProvider>
    <App />
    </AuthContextProvider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

