import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AuthContext } from '../context/AuthContext'

const ViewDonation = () => {

    const { user } = useContext(AuthContext)
    const { id } = useParams()
    const [donation, setDonation] = useState({})
    const [userInfo, setUserInfo] = useState({})

    useEffect(() => {
        const fetchDonation = async () => {
            const response = await axios.get(`https://donationkiosk.bettertrade.net/api/donations/${user?.email}/${id}`)
            setDonation(response);
        }
        fetchDonation();
    }, [])

    useEffect(() => {
        const fetchUserInfo = async () => {
            const response = await axios.get(`https://donationkiosk.bettertrade.net/api/auth/info/${user.email}`)
            setUserInfo(response)
        }
        fetchUserInfo()
    }, [user.email])

    return (
        <div style={{
            width: '100vw', height: '94vh', display: 'flex', alignItems: 'self-start',
            justifyContent: 'center', 
             backgroundColor: 'rgb(235,235,235)', overflow: 'hidden'
        }} >

            <div style={{width: '20vw', display: 'flex',height: 'unset',
             flexDirection: 'column',
             backgroundColor: 'white', marginTop: '20px'}}>
                <div id="capture">

                    <div className="imageWrapper">
                        <h3 style={{ marginBottom: '10px', color: '#ffffe6', alignSelf: 'flex-start' }}>Beneficiary:</h3>
                        <img src="/stub.png" alt="" style={{
                            objectFit: 'fill',
                            width: '50px', height: '50px', marginBottom: '10px'
                        }} />
                        <h4>{donation?.data?.merchantName}</h4>
                    </div>
            <div className="donorInfo">
                <h3 style={{ marginBottom: '10px' }}>Donor:</h3>
        <p style={{ fontSize: '14px' }}>{user?.firstName + ' ' + user?.lastName}</p>
        <p style={{ fontSize: '14px' }}>{userInfo?.data?.address}</p>
        <p style={{ fontSize: '14px' }}>{userInfo?.data?.city + " " + userInfo?.data?.state + " " + userInfo?.data?.zip}</p>
        <p style={{ fontSize: '14px' }}>{userInfo?.data?.phone}</p>
        <p style={{ fontSize: '14px' }}>{userInfo?.data?.email}</p>
            </div>
                    <div className="amount"><p>$ &#160;</p><strong style={{ fontSize: '40px' }}>{(donation?.data?.amount / 100).toFixed(2)}</strong>
                    </div>
                    <div className="reasons">
                        <p>{donation?.data?.reason + ":"}</p>
                        <p>{"$" + (donation?.data?.amount / 100).toFixed(2)}</p>
                    </div>
                    <hr />
                    <div className="total">
                        <strong style={{ fontSize: '12px' }}>Total: </strong>
                        <p style={{ fontSize: '12px' }}>{"$" + (donation?.data?.amount / 100).toFixed(2)}</p>
                    </div>
                    <hr />
                    <div style={{ padding: 40 }}>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewDonation
