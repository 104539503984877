import {useContext} from 'react';
import 'semantic-ui-css/semantic.min.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ReactNotification from 'react-notifications-component'
import './App.css';
import 'normalize.css'
import 'react-notifications-component/dist/theme.css'
import "react-datepicker/dist/react-datepicker.css";
import {AuthContext} from './context/AuthContext';
import Account from './pages/Account/Account';
import Login from './pages/Login/Login';
import CheckEmail from './pages/CheckEmail/CheckEmail';
import KioskAdmin from './pages/KioskAdmin/KioskAdmin';
// import Print from './pages/Print';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Activation from './pages/Activation/Activation';
import ViewDonation from './pages/ViewDonation';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Print from './pages/Print';
import Loggedpage from "./pages/Merchant/Loggedpage";
import MerchantHome from "./pages/Merchant/MerchantHome";
import MerchantLoginFailed from "./pages/Merchant/MerchantLoginFailed";

function App() {
  const { user } = useContext(AuthContext)



  return (
    <Router
        // basename={"/donationkiosk"}
    >
      <div className="app">
        <ReactNotification />
        <div className='workspace-container'>
          <Header />
          <div>
            <Switch >
              <Route exact path="/print/:email/:id" >
      <Print />
        </Route>
              <Route exact path="/api/check">
                <CheckEmail />
              </Route>
              <Route path="/admin/panel">
                <KioskAdmin />
              </Route>
              <Route exact path="/">
                {user?.token ? <Account /> : <Login />}
              </Route>
              <Route exact path="/api/login">
                {user?.token ? <Account /> : <Login />}
              </Route>
              <Route exact path="/activate/:activationCode" >
                {user?.token ? <Account /> : <Activation />}
              </Route>
              <Route exact path="/view/:id" >
                <ViewDonation />
              </Route>
              <Route exact path="/reset/:activationCode">
                {user?.token ? <Account /> : <ResetPassword />}
              </Route>
                <Route path="/donationkiosk/merchant/logged">
                    <Loggedpage setLoginInfo={(loginInfo=> console.log(loginInfo))}/>
                </Route>
                <Route path="/merchant/logged">
                    <Loggedpage setLoginInfo={(loginInfo=> console.log(loginInfo))}/>
                </Route>
                <Route exact path="/merchant">
                    <MerchantHome/>
                </Route>
                <Route exact path="/merchant/loginfailed">
                    <MerchantLoginFailed/>
                </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
