import React from 'react'
import { Card, Image, Divider } from 'semantic-ui-react'
import './style.css'


const UserCard = ({ user }) => {
    return (
        <Card className="user_card_container">
            <Card.Content>
                <Card.Header textAlign="center"><Image src="/no-avatar.png" circular size="small" /></Card.Header>
                <Card.Description textAlign="center">
                    <h2>{user.firstName + " " + user.lastName}</h2>
                </Card.Description>
                <Divider />
                <Card.Description textAlign="center">
                    <h3 className="user_card_donation_label">Donations</h3>
                </Card.Description>
                <Divider />
                <Card.Description textAlign="center" />
            </Card.Content>
        </Card>
    )
}

export default UserCard
